<template>
  <div class="row align-items-center justify-content-center h-100 w-100">
    <div class="col-lg-4 col-md-6 col-sm-12 px-5">
      <form @submit.prevent="login">
        <div class="text-center">
          <img src="@/assets/images/logo.png" alt="logo" height="200" />
          <h2 class="">Login</h2>
          <p>Seller Portal Login</p>
        </div>

        <!-- Loading Indicator -->
        <div class="d-flex justify-content-center">
          <img src="@/assets/images/loading.gif" alt="logo" height="80" v-show="loading" />
        </div>

        <!-- Error message -->
        <div :v-show="error" class="text-center text-danger">
          {{ error }}
        </div>
        <!-- Username -->
        <div class="form-group input-group my-3">
          <div class="input-group-prepend">
            <span class="input-group-text h-100">
              <i class="fa fa-at"></i></span>
          </div>
          <input required v-model="email" type="text" placeholder="Email" class="form-control" />
        </div>

        <!-- Password -->
        <div class="form-group input-group my-3">
          <div class="input-group-prepend">
            <span class="input-group-text h-100">
              <i class="fa fa-lock"></i>
            </span>
          </div>
          <input required v-model="password" v-if="showPassword" type="text" placeholder="Password" class="form-control"
            id="textPassword" />
          <input v-model="password" v-else type="password" placeholder="Password" class="form-control"
            id="passwordInput" />

          <button v-if="password" @click="this.showPassword = !this.showPassword" type="button" class="btn border"
            :class="{ ' btn-warning': this.showPassword }">
            <i class="fa fa-eye" aria-hidden="true"></i>
          </button>
        </div>
        <div class="d-flex justify-content-center">
          <div style="height: 50px; width: 120px">
            <button id="login" type="submit" class="theme-button theme-background-light" @click="login">
              Log in
            </button>
          </div>
        </div>
      </form>
      <router-link to="/forgotPassword">
        <p class="text-center my-4 text-primary">Forgot Password</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      error: "",
      email: "",
      password: "",
      showPassword: false
    };
  },
  methods: {
    login(e) {
      // if(e.submitter.id !== "login") return null;
      this.loading = true;
      this.error = "";

      // Call the store login
      this.$store
        .dispatch("auth/login", {
          email: this.email,
          password: this.password,
        })
        .then(
          () => {
            this.$router.push("/");
          },
          (error) => {
            this.loading = false;
            this.error =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            console.log(error);
          }
        );
    },
  },
};
</script>
